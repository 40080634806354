<template>
  <v-row>
    <v-col cols="12">
        <!-- <steppers-campaign :step="2"></steppers-campaign> -->
        <!-- <package-item></package-item> -->
        <!-- <unit-influencer></unit-influencer> -->
        <!-- <category-item></category-item> -->
        <item-detail></item-detail>
        <!-- <daftar-influencer></daftar-influencer> -->
    </v-col>
  </v-row>
</template>

<script>
import DaftarInfluencer from './components/Table.vue'
import PackageItem from './components/Packages.vue'
import CategoryItem from './components/Categories.vue'
import UnitInfluencer from './components/Unit.vue'
import SteppersCampaign from './components/Steppers.vue'
import ItemDetail from './components/ItemDetail.vue'

export default {
  components: {
    PackageItem,
    DaftarInfluencer,
    CategoryItem,
    UnitInfluencer,
    SteppersCampaign,
    ItemDetail,
  },
  setup() {
    return {}
  },
}
</script>
