<template>
  <div>
    <!-- <v-card>
      <v-card-title class="font-weight-bold"><div>Item Detail</div></v-card-title> -->
    <p class="headline black--text font-weight-bold">Item Detail</p>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
          v-for="n in 2" :key="n"
            class="mx-auto pa-1"
            width="600"
            type="card"
          ></v-skeleton-loader>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <v-row class="mt-0">
        <div class="col-md-4 col-lg-6">
          <v-card class="overflow-hidden elevation-1">
            <v-card-title class="subtitle-1 font-weight-bold">{{ itemData.name }}</v-card-title>
            <v-card-text>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions class="d-flex justify-center subtitle-2">
              <div class="col-md-6">
                <p class="d-flex align-center mb-0">
                  <i class="bi bi-person-workspace"></i>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-workspace primary--text mr-2" viewBox="0 0 16 16">
                    <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                    <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                  </svg>
                  <span class="ml-1 text-capitalize">{{ itemData.description | labelFormat }}</span></p><br>
                <p class="d-flex align-center mb-0">
                  <b-icon icon="image" class="primary--text text-24 mr-2"></b-icon>
                  <span class="ml-1 text-capitalize">{{ itemData.content | labelFormat}}</span></p><br>
                <p class="d-flex align-center mb-0">
                  <b-icon icon="calendar-3" class="primary--text text-24 mr-2"></b-icon>
                  <span class="ml-1 text-capitalize">{{itemData.duration}}</span></p>
              </div>
              <div class="col-md-6">
                <p class="d-flex align-center mb-6">
                  <b-icon icon="bar-chart" class="primary--text text-24 mr-2"></b-icon>
                  <span class="ml-1" v-if="itemData.type === 'B'">Micro</span><span class="ml-1" v-else>Nano</span>
                </p>
                <p class="d-flex align-center mb-0">
                  <b-icon icon="person" class="primary--text text-24 mr-2"></b-icon>
                  <span class="ml-1">{{ itemData.qty}} Influencer</span>
                </p><br>
                <p class="d-flex align-center mb-0" v-if="!isMobile()">
                  <b-icon icon="person-check" class="primary--text text-24 mr-2"></b-icon>
                  <span :class="selected === bpm ? '':'error--text'">{{ selected }}</span>/{{ bpm }} Influencer Terpilih
                </p>
              </div>
            </v-card-actions>
          </v-card>
        </div>
        <div class="col-md-6">
          <v-card class="elevation-1">
            <v-list two-line>
              <template>
                <v-subheader class="font-weight-bold body-1"
                >
                  Harga Detail
                </v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title >Harga Per Influencer</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text><h2>Rp {{ itemData.price/itemData.qty | numberFormat}}</h2></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">Total Harga <span class="font-weight-medium subtitle-2">({{ itemData.qty }} Influencer)</span></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text><h2>Rp {{ itemData.price | numberFormat}}</h2></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <div class="row ml-1 mr-1">
              <div class="col-md-6">
              <v-btn
                color="primary"
                class="text-capitalize"
                block
                @click="validate('cart')"
                >
                <b-icon icon="cart3" class="white--text text-24 mr-2"></b-icon> Masukkan Keranjang
              </v-btn>
              </div>
              <div class="col-md-6">
                <v-btn
                  color="primary"
                  class="text-capitalize"
                  block
                  outlined
                  @click="validate('payment')"
                ><i class="far fa-credit-card mr-3"></i>
                  checkout sekarang
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>
        <!-- <br>
      </v-card> -->
      <!-- <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        v-if="(item === 'custom')"
      >
        <v-row class="mt-0 mb-0" no-gutters>
            <div class="col-md-6 col-lg-6">
              <v-card class="elevation-1 pa-5">
                <v-select
                  v-model="selectType"
                  :items="ContentType"
                  label="Tipe Konten"
                  :rules="[v => !!v || 'Tipe belum dipilih']"
                  outlined
                  required
                  dense
                ></v-select>

                <v-select
                  :items="ContentType"
                  label="Format Konten"
                  :rules="[v => !!v || 'Format belum dipilih']"
                  outlined
                  dense
                  required
                ></v-select>

                <v-select
                  :items="ContentType"
                  label="Level Influencer"
                  :rules="[v => !!v || 'Level belum dipilih']"
                  outlined
                  dense
                  required
                ></v-select>

                <v-row class="mt-0 mb-0" no-gutters style="height:40px">
                  <v-col
                    class="d-flex"
                    cols="12"
                    sm="6"
                    lg="6"
                  >
                    <div class="mt-1 pl-2 pt-1 subtitle-1">Jumlah Influncer</div>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="12"
                    sm="6"
                    lg="6"
                  >
                    <v-btn class="pa-5" :disabled="disableMinus" small @click="decrement" color="primary" height="30">
                        <i class="fa fa-minus" aria-hidden="true"></i>
                    </v-btn>
                    <v-text-field
                      outlined
                      v-model="bpm"
                      max-w
                      class="ml-3 mr-3 centered-input font-weight-bold"
                      :rules="influencerRules"
                      dense
                      required
                    ></v-text-field>
                    <v-btn class="pa-5" :disabled="disablePlus" small color="primary" @click="increment">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div class="col-md-6">
              <v-card class="ml-5 elevation-1">
                <v-list two-line>
                  <template>
                    <v-subheader class="font-weight-bold body-1"
                    >
                      Harga Detail
                    </v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title >Harga Per Influencer</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text><h2>Rp {{influencerPrice | numberFormat}}</h2></v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">Total Harga</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text><h2>Rp {{totalPrice | numberFormat}}</h2></v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
                <div class="row ml-1 mr-1">

              <div class="col-md-6">
              <v-btn
                  color="primary"
                  class="text-capitalize"
                  block
                  @click="validate('cart')"
                  >
                  <b-icon icon="cart3" class="white--text text-24 mr-2"></b-icon> Masukkan Keranjang
              </v-btn>
              </div>
              <div class="col-md-6">
                <v-btn
                  color="primary"
                  class="text-capitalize"
                  block
                  outlined
                  @click="validate('payment')"
                ><i class="far fa-credit-card mr-3"></i>
                  lanjut ke pembayaran
                </v-btn>
              </div>
              </div>
              </v-card>
            </div>
        </v-row>
      </v-form> -->
      <v-row justify="center">
        <v-dialog
          v-model="cartDialog"
          max-width="400"
        >
          <v-card>
            <div class="d-flex justify-center pt-15">
              <v-progress-circular
                v-if="isLoadingCart"
                :width="5"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <div v-else>
                <i v-if="cartSuccess" class="far fa-check-circle primary--text" style="font-size:55px"></i>
                <i v-else class="far fa-times-circle error--text" style="font-size:55px"></i>
              </div>
            </div>
            <v-card-title class="d-flex justify-center">
              <div v-if="!isLoadingCart">
                <p v-if="cartSuccess" class="text-h5 primary--text mb-0">Item telah masuk keranjang</p>
                <p v-else class="text-h5 error--text mb-0">Item gagal masuk keranjang</p>
              </div>
            </v-card-title>
            <!-- <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text> -->
            <v-card-actions v-if="!isLoadingCart" class="d-flex justify-center">
              <!-- <v-spacer></v-spacer> -->
                <v-btn
                  color="primary"
                  class="text-capitalize mr-1"
                  @click="goToCart"
                ><i class="fas fa-shopping-cart mr-2"></i>
                  lihat keranjang
                </v-btn>
                <v-btn
                  color="primary"
                  class="text-capitalize ml-1"
                  outlined
                  @click="goToStarted"
                >
                <!-- <i class="far fa-credit-card mr-2"></i> -->
                <i class="fas fa-boxes mr-2"></i>
                  Kembali ke Paket
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <v-dialog
      v-model="alert"
      max-width="400"
    >
      <v-alert type="error">
        {{alertMessage}} <button style="float:right" type="button" v-on:click="closeAlert" class="close right" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </v-alert>
    </v-dialog>
    <br>
    <div v-if="!isLoading">
      <daftar-influencer :qty="itemData.qty" :type="itemData.type" @eventValue="_selectedInfluencer"></daftar-influencer>
      <div class="d-flex justify-center">
      <v-card width="600">
        <v-card-text class="d-flex justify-center">
          <p class="d-flex align-center mb-0">
            <b-icon icon="person-check" class="primary--text text-24 mr-2"></b-icon>
            <span :class="selected === bpm ? '':'error--text'">{{ selected }}</span>/{{ bpm }} Influencer Terpilih <strong class="ml-1"></strong>
          </p>
        </v-card-text>
        <v-card-text class="d-flex justify-center">
          <div class="row">
            <div class="col-md-4 col-lg-6 col-sm-12">
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="validate('cart')"
                block
                >
                <b-icon icon="cart3" class="white--text text-24 mr-2"></b-icon> Masukkan Keranjang
              </v-btn>
            </div>
            <div class="col-md-4 col-lg-6 col-sm-12">
              <v-btn
                color="primary"
                class="text-capitalize"
                outlined
                block
                @click="validate('payment')"
              ><i class="far fa-credit-card mr-3"></i>
                checkout sekarang
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DaftarInfluencer from './Table.vue'

import axios from 'axios'

export default defineComponent({
  components: {
    DaftarInfluencer
  },
  setup() {
    return{
      itemId:'',
      itemData:[],
      packageId:'',
      isLoading:true,
      bpm: 0,
      alert:false,
      cartDialog:false,
      isLoadingCart:true,
      cartSuccess:false,
      alertMessage:'',
      selected:0,
      selectedInfluencer:[]
    }
  },
  mounted(){
    this.item = this.$route.query.item
    this.itemId = this.decryptData(this.item)
    this.package = this.$route.query.package
    this.packageId = this.decryptData(this.package)
    this.loadPackage();
  },
  methods:{
    // this.$CryptoJS.AES.decrypt(encryptedText, "Secret Passphrase").toString(this.CryptoJS.enc.Utf8)
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadPackage(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.post(
        process.env.VUE_APP_API_ENDPOINT + 'packages/onePackage', {id:this.itemId, type:this.packageId}, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          // console.log(response)
          if(response.status === 201){
            this.itemData = response.data
            if(this.packageId === 'custom'){
              this.itemData.description = response.data.type
              this.itemData.type = response.data.level
              this.itemData.content = response.data.format
            }
            this.bpm = parseInt(this.itemData.qty);
            setTimeout(() => { 
              this.isLoading = false
            }, 200);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    decrement () {
      if(this.bpm <= 1){
        this.disableMinus = true
      } else {
        this.disableMinus = false
        this.bpm--
        this.totalPrice = this.influencerPrice*this.bpm
        if(this.bpm <= 1){
          this.disableMinus = true
        }
      }
      if(this.bpm >= this.maxInfluencers){
        this.disablePlus = true
      } else {
        this.disablePlus = false
      }
    },
    increment () {
      if(this.bpm >= this.maxInfluencers){
        this.disablePlus = true
      } else {
        this.disablePlus = false
        this.bpm++
        this.totalPrice = this.influencerPrice*this.bpm
         if(this.bpm >= this.maxInfluencers){
          this.disablePlus = true
        }
      }
      if(this.bpm <= 1){
        this.disableMinus = true
      } else {
        this.disableMinus = false
      }
    },
    goToStarted(){
      this.$router.push({name:'campaign'}); 
    },
    validate(btn){
      if(this.item === 'custom'){
        let checkValid = this.$refs.form.validate()
        if(checkValid){
          this.checkForm(btn);
        }
      } else {
        this.checkForm(btn);
      }
    },
    checkForm(btn){
      if(this.bpm === this.selected){
        if(btn === 'cart'){
          this.addToCart()
          this.cartDialog = true
        } else {
          this.goToPayment()
        }
      } else if(this.bpm < this.selected){
        this.alertMessage = 'Influencer melebihi quantity.'
        this.alert = true;
      } else if(this.bpm > this.selected){
        if(this.selected <= 0){
          this.alertMessage = 'Influencer belum dipilih.'
          this.alert = true;
        } else {
          this.alertMessage = 'Influencer belum cukup quantity.'
          this.alert = true;
        }
      } else {
        console.log(this.bpm)
        console.log(this.selected)
          this.alertMessage = 'Terjadi kesalahan. Silahkan coba lagi.'
          this.alert = true;
      }
    },
    addToCart(){
      this.isLoadingCart = true;
      let token = localStorage.getItem('token')
      axios.post(
        process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/addToCart', {
          package_id:this.itemId,
          influencer_id: this.selectedInfluencer,
          type: this.packageId
        }, {
          headers: {
          'Authorization': 'Bearer '+ token
        }
      }).then(response => {
        // console.log(response)
        if(response){
          // this.itemData = response.data
          // this.bpm = this.itemData.qty;
          setTimeout(() => { 
            this.isLoadingCart = false
            this.cartSuccess = true
          }, 200);
        }
      }).catch(error => {
        console.log(error)
        setTimeout(() => { 
          this.isLoadingCart = false
          this.cartSuccess = false
        }, 200);
      });
    },
    goToPayment(){
      let dataCheckout = {
        package_id:this.itemId,
        influencer_id:this.selectedInfluencer,
        type: this.packageId
      }
      localStorage.setItem('checkout', '')
      localStorage.setItem('checkout', JSON.stringify(dataCheckout))
      localStorage.setItem('checkoutNow', true)
      this.$router.push({name:'campaign-payment'});
    },
    goToCart(){
      this.$router.push({name:'campaign-cart'}); 
    },
    _selectedInfluencer(value){
      let newValue = '';
      for(let a = 0; a < value.length; a++){
        newValue += value[a].id+',';
      }
      this.selectedInfluencer = newValue;
      this.selected = value.length;
      // if(value === this.bpm){
      //   this.disabledCart = false
      // } else {
      //   this.disabledCart = true
      // }
    },
    closeAlert(){
      this.alert = false
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
    //this.$router.push({name:'campaign-payment'}); 
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    }
  },
})
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center;
    }
</style>